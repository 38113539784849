import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logoImg } from "../utils";
import { navLists } from "../constants";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="w-full py-5 px-5 flex justify-between items-center">
            <nav className="flex items-center justify-between w-full max-w-screen-xl">
                <img src={logoImg} alt="2nd World" className="h-11 w-11" />

                {/* Hamburger icon on the right side */}
                <button 
                    className="sm:hidden ml-auto"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    aria-label="Toggle navigation"
                >
                    <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
                    </svg>
                </button>

                {/* Navigation links */}
                <div className={`absolute top-16 right-0 bg-zinc-50 shadow-md w-full sm:w-auto sm:static sm:bg-transparent sm:shadow-none flex flex-col sm:flex-row items-center justify-center ${isMenuOpen ? "block" : "hidden"} sm:flex z-10`}>
                    {navLists.map((nav, index) => {
                        if (nav === "ToolAI Web4 Browser") {
                            return (
                                <a 
                                    key={index} 
                                    href="https://toolai.ai/" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="px-5 py-2 text-sm text-gray-600 hover:text-blue-600 sm:text-gray-600 sm:hover:bg-gray-200 transition-colors"
                                >
                                    {nav}
                                </a>
                            );
                        }
                        return (
                            <Link 
                                key={index} 
                                to={`/${nav.replace(/\s+/g, '').toLowerCase()}`} 
                                className="px-5 py-2 text-sm text-gray-600 hover:text-blue-600 sm:text-gray-600 sm:hover:bg-gray-200 transition-colors"
                            >
                                {nav}
                            </Link>
                        );
                    })}
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
