import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // React Router ekliyoruz
import Navbar from './components/Navbar';
import * as Sentry from '@sentry/react';

const Hero = React.lazy(() => import('./components/Hero'));
const Highlights = React.lazy(() => import('./components/Highlights'));
const Model = React.lazy(() => import('./components/Model'));
const Concert = React.lazy(() => import('./components/Concert'));
const Features = React.lazy(() => import('./components/Features'));
const HowItWorks = React.lazy(() => import('./components/HowItWorks'));
const FAQ = React.lazy(() => import('./components/FAQ'));
const Footer = React.lazy(() => import('./components/Footer'));

const App = () => {
  const [componentLoaded, setComponentLoaded] = useState(0);

  useEffect(() => {
    if (componentLoaded === 0) {
      import('./components/Hero').then(() => setComponentLoaded(1));
    } else if (componentLoaded === 1) {
      import('./components/Highlights').then(() => setComponentLoaded(2));
    } else if (componentLoaded === 2) {
      import('./components/Model').then(() => setComponentLoaded(3));
    } else if (componentLoaded === 3) {
      import('./components/Concert').then(() => setComponentLoaded(4));
    } else if (componentLoaded === 4) {
      import('./components/Features').then(() => setComponentLoaded(5));
    } else if (componentLoaded === 5) {
      import('./components/HowItWorks').then(() => setComponentLoaded(6));
    } else if (componentLoaded === 6) {
      import('./components/FAQ').then(() => setComponentLoaded(7));
    } else if (componentLoaded === 7) {
      import('./components/Footer').then(() => setComponentLoaded(8));
    }
  }, [componentLoaded]);

  return (
    <Router> {/* Router ile uygulamayı sarmalıyoruz */}
      <main className="bg-black">
        <Navbar />
        <Suspense fallback={
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-3xl">
            <div className="animate-ping absolute inline-flex h-16 w-16 rounded-full bg-blue-400 opacity-75"></div>
            Loading...
          </div>
        }>
          <Routes>
            <Route path="/" element={
              <>
                {componentLoaded >= 1 && <Hero />}
                {componentLoaded >= 2 && <Highlights />}
                {componentLoaded >= 3 && <Model />}
                {componentLoaded >= 4 && <Concert />}
                {componentLoaded >= 5 && <Features />}
                {componentLoaded >= 6 && <HowItWorks />}
                {componentLoaded >= 7 && <FAQ />}
                {componentLoaded >= 8 && <Footer />}
              </>
            } />
            {/* Diğer rotalar da burada tanımlanabilir */}
          </Routes>
        </Suspense>
      </main>
    </Router>
  );
}

export default Sentry.withProfiler(App);
